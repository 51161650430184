.heading {
  padding: 2rem 0 1rem;
  border-bottom: 2px solid var(--gray-border);
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-text);
  font-size: 1.25rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  @media screen and (min-width: 45em) {
    padding: 2rem 2rem 1rem 2rem;
  }
}
